import React from 'react';
import LineChart from './LineChart';

const LineChartContainer = ({ data, xField, yField, xLabel, yLabel, graphTitle }) => {
  if (!data) return null;
  if (data.length === 0) return <div></div>;
  return (
    <div className="flex flex-col items-center justify-center p-2">
      <LineChart data={data} graphTitle={graphTitle} xField={xField} yField={yField} xLabel={xLabel} yLabel={yLabel} />
  </div>
  
  );
};


export default LineChartContainer;
