import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import cauldronImage from '../assets/cauldron.png';

export default function Header({
    dbSchema,
    hasSubscription,
    handleOpenDatabaseModal,
    setShowStripeModal,
    logout,
    userEmail,
    stripePromise,
    showStripeModal,
  }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <header className="bg-white shadow-sm rounded-xl"> {/* Applied shadow and rounded border */}
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-6" aria-label="Global">
                
     
    <div className="flex lg:flex-1">
  <a href="#" className="-m-1 p-1">
    <h1 className="text-xl lg:text-2xl font-bold">Magic Dash</h1> {/* Adjust font size and style as needed */}
  </a>
</div>

            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-0">
              <a href="/" className="bg-F06565 text-455964 py-3 px-6 text-base font-semibold rounded-2xl hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-F06565 transition duration-150 ease-in-out mr-4">
                Query
              </a>
              {dbSchema && (
                <a href="/dashboard" className="bg-F06565 text-455964 py-3 px-6 text-base font-semibold rounded-2xl hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-F06565 transition duration-150 ease-in-out mr-4">
                  Dashboard
                </a>
              )}
              <button
                onClick={handleOpenDatabaseModal}
                className="bg-F06565 text-455964 py-3 px-6 text-base font-semibold rounded-2xl hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-F06565 transition duration-150 ease-in-out mr-4">
                DB Settings
              </button>
              {!hasSubscription && (
                <button onClick={() => setShowStripeModal(true)} className="bg-F06565 text-455964 py-3 px-6 text-base font-bold rounded-2xl hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-F06565 transition duration-150 ease-in-out mr-4">
                  Get Magic Dash ✨
                </button>
              )}
              <button
                onClick={logout}
                className="bg-F06565 text-455964 py-3 px-6 text-base font-semibold rounded-2xl hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-F06565 transition duration-150 ease-in-out">
                Logout
              </button>
            </div>
          </nav>
          <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-10" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-4 py-4 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1 p-1">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2 rounded-md p-2 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    <a href="/" className="-mx-2 block rounded-2xl px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                      Query
                    </a>
                    {dbSchema && (
                      <a href="/dashboard" className="-mx-2 block rounded-2xl px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                      Dashboard
                    </a>
                  )}
                  <button
                    onClick={handleOpenDatabaseModal}
                    className="-mx-2 block rounded-2xl px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    DB Settings
                  </button>
                  {!hasSubscription && (
                    <button
                      onClick={() => setShowStripeModal(true)}
                      className="-mx-2 block rounded-2xl px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                      Get Magic Dash ✨
                    </button>
                  )}
                  <button
                    onClick={logout}
                    className="-mx-2 block rounded-2xl px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    );
  
}
