import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useAuth } from '../Auth/AuthContext';

function StripeCheckoutModal({ onClose, email }) {
    const stripe = useStripe();
    const elements = useElements();
    const { authToken } = useAuth();

    const handleCheckout = async () => {
        if (!stripe || !elements) return;
        try {
            const response = await fetch('/create-checkout-session', { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                  },
                body: JSON.stringify({
                    email: email  // Pass the email to your backend
                })
            });
            const session = await response.json();

            const result = await stripe.redirectToCheckout({ sessionId: session.id });

            if (result.error) {
                console.error(result.error.message);
            }
        } catch (error) {
            console.error("Checkout error:", error);
        }
    };

    return (
<div className="fixed inset-0 z-10 flex items-center justify-center bg-opacity-50 bg-black">
    <div className="bg-white p-8 rounded-lg shadow-xl max-w-xl w-full">
        <h3 className="text-2xl font-semibold text-gray-700 mb-5">Unlock your data insights.</h3>
        
        <ul className="list-disc pl-5 mb-5 text-gray-600">
            <li className="mb-2"><b>Instant Insights:</b> Get insights instantly, no delays.</li>
            <li className="mb-2"><b>Zero Coding:</b> Explore data, no queries needed.</li>
            <li className="mb-2"><b>All-in-One Dashboard:</b> All key insights in one view.</li>
            <li className="mb-2"><b>Cost-Effective:</b> Save on data specialists.</li>
        </ul>

        <div className="flex justify-between mt-6">
            <button 
                onClick={handleCheckout} 
                className="w-full bg-blue-600 text-white py-2 px-6 text-lg font-semibold rounded hover:bg-blue-700 active:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-150 ease-in-out mr-3"
            >
                Get Magic Dash ✨
            </button>
            <button 
                onClick={onClose} 
                className="w-full bg-gray-300 text-gray-700 py-2 px-6 text-lg font-semibold rounded hover:bg-gray-400 active:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 transition duration-150 ease-in-out"
            >
                Close
            </button>
        </div>
    </div>
</div>

    );
}

export default StripeCheckoutModal;
