import React, { useState } from 'react';
import GetSchemaScriptModal from './GetSchemaScriptModal';

function DatabaseModal({ onClose, onSave, handleSchemaSubmit }) {
  const [connectionString, setConnectionString] = useState('');
  const [showGetSchemaScriptModal, setShowGetSchemaScriptModal] = useState(false);
  const [databaseType, setDatabaseType] = useState('mongo');

  const handleSave = () => {
    onSave(connectionString, databaseType);
    onClose();
  };

  const handleOpenGetSchemaScriptModal = () => {
    setShowGetSchemaScriptModal(true);
  };

  return (
    <>
      <div className="fixed inset-0 z-10 flex items-center justify-center bg-opacity-50 bg-black">
        <div style={{ backgroundColor: '#F2F2F2' }} className="p-6 rounded-md shadow-lg w-2/3">
          <h2 className="mb-4 text-2xl text-455964">Database Settings</h2>
          <div className="mb-6">
          <label htmlFor="connectionString" className="text-455964">
            {databaseType === 'mongo' ? 'Connection String:' : 'Personal Access Token:'}
          </label>
            <input
              type="text"
              id="connectionString"
              name="connectionString"
              value={connectionString}
              onChange={(event) => setConnectionString(event.target.value)}
              className="w-full mt-2 p-3 bg-white rounded-xl border border-455964 text-lg text-455964 focus:outline-none focus:ring-455964 focus:border-455964 focus:z-10"
            />
            <p><b>{databaseType === 'mongo' ? '' : 'Scopes required: data.records:read, schema.bases:read'}</b></p>
          </div>
          <div className="mb-6">
            <label htmlFor="databaseType" className="text-455964">
              Database Type:
            </label>
            <select
              id="databaseType"
              name="databaseType"
              value={databaseType}
              onChange={(event) => setDatabaseType(event.target.value)}
              className="w-full mt-2 p-3 bg-white rounded-xl border border-455964 text-lg text-455964 focus:outline-none focus:ring-455964 focus:border-455964 focus:z-10"
            >
              <option value="mongo">MongoDB</option>
              <option value="airtable">Airtable</option>
            </select>
          </div>
          <div className="mb-4">
  {
    databaseType === 'airtable' ? (
      <a href="https://airtable.com/developers/web/guides/personal-access-tokens#creating-a-token" 
         className="ml-2 text-blue-600 underline" 
         target="_blank" 
         rel="noopener noreferrer">
        How to generate my personal access token
      </a>
    ) : (
      <button onClick={handleOpenGetSchemaScriptModal} 
              className="ml-2 text-blue-600 underline">
        Just want to generate queries without having to connect your db?
      </button>
    )
  }
</div>

          <div className="flex justify-end space-x-4">
            <button
              onClick={handleSave}
              className="bg-black text-white py-2 px-5 text-lg font-semibold rounded-xl hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black transition duration-150 ease-in-out"
            >
              Save
            </button>
            <button
              onClick={onClose}
              className="bg-black text-white py-2 px-5 text-lg font-semibold rounded-xl hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black transition duration-150 ease-in-out"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      {showGetSchemaScriptModal && <GetSchemaScriptModal handleSchemaSubmit={handleSchemaSubmit} onClose={() => setShowGetSchemaScriptModal(false)} />}
    </>
  );
}

export default DatabaseModal;