import React, { useState } from 'react';
import { useAuth } from '../Auth/AuthContext';

function SaveGraphModal({ onClose, data, onSave, userEmail, dbType }) {
    const [graphData, setGraphData] = useState(data);
    const { authToken } = useAuth();

    const handleSave = async () => {
        try {
            const response = await fetch('/saveGraph', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                  },
                body: JSON.stringify({
                    graphData,
                    email: userEmail,
                    dbType
                })
            });
    
            const responseData = await response.json();
            
            if (response.ok) {
                onSave(graphData);
                onClose();
            } else {
                console.error("Failed to save graph:", responseData.error);
            }
        } catch (err) {
            console.error("Error saving graph:", err);
        }
    };
    

    return (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-opacity-50 bg-black">
            <div style={{backgroundColor: '#F2F2F2'}} className="p-6 rounded-md shadow-lg w-1/3">
                <h2 className="mb-4 text-2xl text-455964">Save Graph to Dashboard</h2>

                <div className="mb-6">
                    <label htmlFor="graphTitle" className="text-455964">Graph Title:</label>
                    <input
                        type="text"
                        id="graphTitle"
                        value={graphData.graphTitle}
                        onChange={(e) => setGraphData({ ...graphData, graphTitle: e.target.value })}
                        className="w-full mt-2 p-3 bg-white rounded-xl border border-455964 placeholder-gray-500 text-lg text-455964 focus:outline-none focus:ring-455964 focus:border-455964 focus:z-10"
                    />
                </div>

                <div className="mb-6">
                    <label htmlFor="xLabel" className="text-455964">X Axis Label:</label>
                    <input
                        type="text"
                        id="xLabel"
                        value={graphData.xLabel}
                        onChange={(e) => setGraphData({ ...graphData, xLabel: e.target.value })}
                        className="w-full mt-2 p-3 bg-white rounded-xl border border-455964 placeholder-gray-500 text-lg text-455964 focus:outline-none focus:ring-455964 focus:border-455964 focus:z-10"
                    />
                </div>

                <div className="mb-6">
                    <label htmlFor="yLabel" className="text-455964">Y Axis Label:</label>
                    <input
                        type="text"
                        id="yLabel"
                        value={graphData.yLabel}
                        onChange={(e) => setGraphData({ ...graphData, yLabel: e.target.value })}
                        className="w-full mt-2 p-3 bg-white rounded-xl border border-455964 placeholder-gray-500 text-lg text-455964 focus:outline-none focus:ring-455964 focus:border-455964 focus:z-10"
                    />
                </div>

                <div className="flex justify-end space-x-4">
                    <button onClick={handleSave} className="bg-black text-white py-2 px-5 text-lg font-semibold rounded-xl hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black transition duration-150 ease-in-out">
                        Save
                    </button>
                    <button onClick={onClose} className="bg-black text-white py-2 px-5 text-lg font-semibold rounded-xl hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black transition duration-150 ease-in-out">
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SaveGraphModal;