import React, { useState, useEffect } from 'react';

function Toast({ message, onClose }) {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 3000); // Adjust the duration as needed

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className="fixed bottom-4 right-4 p-4 bg-green-500 text-white rounded-md shadow-lg">
            {message}
        </div>
    );
}

function GetSchemaScriptModal({ onClose, handleSchemaSubmit }) {
    const [schemaResult, setSchemaResult] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const handleSubmit = async () => {
        try {
            const data = handleSchemaSubmit(schemaResult);
            setToastMessage('Schema Saved');
            setShowToast(true);

        } catch (error) {
            console.error('Network error:', error);
        }
    };

    const copyToClipboard = () => {
        const textToCopy = `import pymongo
from pymongo import MongoClient

def extract_schema(connection_string):
    if connection_string == "YOUR_CONNECTION_STRING_HERE":
        return "Please replace 'YOUR_CONNECTION_STRING_HERE' with your actual MongoDB connection string."

    client = MongoClient(connection_string)
    db = client.get_default_database()
    collections = db.list_collection_names()
    schema = {}

    for collection_name in collections:
        collection = db[collection_name]
        sample_doc = collection.find_one()
        if sample_doc:
            schema[collection_name] = {key: str(type(sample_doc[key])) for key in sample_doc.keys()}

    return schema

connection_string = "YOUR_CONNECTION_STRING_HERE"
result = extract_schema(connection_string)

if isinstance(result, str):
    print(result)
else:
    print(result)
`; // The code you want to copy goes here.
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    };

    return (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-opacity-50 bg-black">
            <div style={{ background: 'linear-gradient(to right, #a276eb, #ef6b70)' }} className="p-6 rounded-md shadow-lg w-2/3">
                <h2 className="mb-4 text-2xl text-white">Extract Schema Locally</h2>

                <p className="mb-6 text-white">Copy and run the script below in a Python environment with pymongo installed:</p>
                <div className="relative bg-white p-4 rounded-lg overflow-y-auto max-h-80 mb-4">
                    <pre>
                        {`import pymongo
from pymongo import MongoClient

def extract_schema(connection_string):
    if connection_string == "YOUR_CONNECTION_STRING_HERE":
        return "Please replace 'YOUR_CONNECTION_STRING_HERE' with your actual MongoDB connection string."

    client = MongoClient(connection_string)
    db = client.get_default_database()
    collections = db.list_collection_names()
    schema = {}

    for collection_name in collections:
        collection = db[collection_name]
        sample_doc = collection.find_one()
        if sample_doc:
            schema[collection_name] = {key: str(type(sample_doc[key])) for key in sample_doc.keys()}

    return schema

connection_string = "YOUR_CONNECTION_STRING_HERE"
result = extract_schema(connection_string)

if isinstance(result, str):
    print(result)
else:
    print(result)
`}
                    </pre>
                    <button onClick={copyToClipboard} className="absolute top-2 right-2 bg-black text-white p-2 rounded-full hover:opacity-90 focus:outline-none">
                        <i className="fa fa-copy"></i>
                    </button>
                </div>

                <p className="mb-4 text-white">Then paste the result below:</p>
                <textarea
                    value={schemaResult}
                    onChange={(e) => setSchemaResult(e.target.value)}
                    rows={6}
                    className="w-full p-3 mb-4 bg-white rounded-xl border border-455964 text-lg text-455964 focus:outline-none focus:ring-455964 focus:border-455964"
                ></textarea>

                <div className="flex justify-end space-x-4">
                    <button
                        onClick={handleSubmit}
                        className="bg-black text-white py-2 px-5 text-lg font-semibold rounded-xl hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black transition duration-150 ease-in-out"
                    >
                        Submit Schema
                    </button>
                    <button onClick={onClose} className="bg-black text-white py-2 px-5 text-lg font-semibold rounded-xl hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black transition duration-150 ease-in-out">
                        Close
                    </button>
                </div>
            </div>
            {showToast && <Toast message={toastMessage} onClose={() => setShowToast(false)} />}
        </div>
    );
}

export default GetSchemaScriptModal;