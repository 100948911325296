import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBDKpxj4Iit0sbI4AUA8N1pGOP5a240Fys",
    authDomain: "dashmagic-ad305.firebaseapp.com",
    projectId: "dashmagic-ad305",
    storageBucket: "dashmagic-ad305.appspot.com",
    messagingSenderId: "601944969981",
    appId: "1:601944969981:web:049f7d9ffd0e62c5882494"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };