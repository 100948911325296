import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useAuth } from '../Auth/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import cauldronImage from '../assets/cauldron.png';
import Notification from '../Tailwind/Notification';

function LoginComponent() {
  const { setAuthToken } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notification, setNotification] = useState({
    show: false,
    type: '',
    message: { title: '', description: '' }
  });
  const [showModal, setShowModal] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const token = await user.getIdToken();
      localStorage.setItem('authToken', token);
      setAuthToken(token);
      navigate('/');
    } catch (error) {
      setNotification({
        show: true,
        type: 'error',
        message: { title: 'Login Failed', description: error.message }
      });
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const token = await user.getIdToken();
      localStorage.setItem('authToken', token);
      setAuthToken(token);
      navigate('/');
    } catch (error) {
      setNotification({
        show: true,
        type: 'error',
        message: { title: 'Login Failed', description: error.message }
      });
    }
  };

  const handleForgotPassword = () => {
    setShowModal(true);
  };

  const handleSendPasswordResetEmail = async (event) => {
    event.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setShowModal(false);
      setNotification({
        show: true,
        type: 'success',
        message: { title: 'Password reset email sent!', description: 'Please check your email to reset your password.' }
      });
    } catch (error) {
      setNotification({
        show: true,
        type: 'error',
        message: { title: 'Error', description: error.message }
      });
    }
  };

  useEffect(() => {
    if (notification.show) {
      const timer = setTimeout(() => {
        setNotification({ ...notification, show: false });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="flex flex-col md:flex-row items-center justify-center md:space-x-10">
        <div className="max-w-md bg-white p-6 sm:p-10 rounded-lg">
          <img src={cauldronImage} alt="Cauldron" className="mx-auto mb-8 w-60" />
          <h2 className="text-4xl font-bold text-center mb-8">Log in</h2>
          <form onSubmit={handleLogin} className="space-y-6">
            <input
              className="w-full p-4 border rounded-md text-lg"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="w-full p-4 border rounded-md text-lg"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="submit"
              style={{ backgroundColor: '#4E39E4' }}
              className="w-full p-4 text-white text-lg rounded-md hover:bg-blue-700"
            >
              Log in
            </button>
            <button
              onClick={handleGoogleLogin}
              className="w-full p-4 text-indigo-600 border-2 border-indigo-600 rounded-md hover:bg-indigo-600 hover:text-white flex items-center justify-center transition-colors duration-300"
            >
              <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24" height="24">
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
              <b>Log in with Google</b>
            </button>

          </form>
          <div className="text-center mt-6 text-lg">
            <button onClick={handleForgotPassword} className="text-indigo-600 hover:underline focus:outline-none">
              Forgot Password
            </button>
          </div>
          <div className="text-center mt-6 text-lg">
            Don't have an account?
            <Link to="/signup" className="text-indigo-600 hover:underline"> Sign up</Link>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-md w-96 text-center">
            <form onSubmit={handleSendPasswordResetEmail}>
              <h2 className="text-lg font-semibold mb-4">Forgot Password</h2>
              <p className="text-gray-600 mb-4">Enter your email address to reset your password.</p>
              <input
                className="w-full p-2 mb-4 border rounded-md"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                type="submit"
                style={{ backgroundColor: '#4E39E4' }}
                className="w-full p-2 text-white rounded-md hover:bg-blue-700"
              >
                Send Reset Email
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="text-indigo-600 hover:underline focus:outline-none mt-4"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
      {notification.show && (
        <Notification
          alertType={notification.type}
          message={notification.message}
          onClose={() => setNotification({ ...notification, show: false })}
        />
      )}
    </div>
  );
}

export default LoginComponent;

