import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './Auth/AuthContext';
import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: 'https://app.posthog.com',
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <React.StrictMode>
      <Router>
        <PostHogProvider 
        apiKey='phc_VwFOblg6E9hVhlbk0hzR1JyrojDjKGyYCgKqRwHirLJ'
        options={options}
      >
        <App />
      </PostHogProvider>
      </Router>
    </React.StrictMode>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
