import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { useAuth } from '../Auth/AuthContext';
import cauldronImage from '../assets/cauldron.png';

function SignUpComponent() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const auth = getAuth();
  const { setAuthToken } = useAuth();

  const validateEmail = (input) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(input);
  };

  const validatePassword = (input) => {
    return input.length >= 6;
  };

  const handleSignUp = async () => {
    setEmailError("");
    setPasswordError("");
    setError("");

    if (!validateEmail(email)) {
      setEmailError("Invalid email address");
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError("Password must be at least 6 characters long");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const token = await user.getIdToken();
      localStorage.setItem('authToken', token);
      setAuthToken(token);
      await storeEmailInMongoDB(email);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const token = await user.getIdToken();
      localStorage.setItem('authToken', token);
      setAuthToken(token);
      await storeEmailInMongoDB(user.email);
    } catch (error) {
      setError(error.message);
    }
  };

  const storeEmailInMongoDB = async (email) => {
    try {
      const response = await fetch('/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleSignUp();
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="flex flex-col md:flex-row items-center justify-center md:space-x-10">
        <div className="max-w-md bg-white p-6 sm:p-10 rounded-lg">
          <img src={cauldronImage} alt="Cauldron" className="mb-8 w-60 mx-auto" />
          <h2 className="text-4xl font-bold text-center mb-8">Sign up</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full p-4 border rounded-md text-lg ${emailError ? 'border-red-500' : 'border-gray-300'
                }`} // Larger input fields and text
            />
            {emailError && <p className="text-red-500 text-sm mt-3">{emailError}</p>} {/* Larger error text */}
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`w-full p-4 border rounded-md text-lg ${passwordError ? 'border-red-500' : 'border-gray-300'
                }`} // Larger input fields and text
            />
            {passwordError && <p className="text-red-500 text-sm mt-3">{passwordError}</p>} {/* Larger error text */}
            <button
              type="submit"
              style={{ backgroundColor: '#4E39E4' }}
              className="w-full p-4 text-white text-lg rounded-md hover:bg-blue-700" // Larger button and text
            >
              Sign up
            </button>
            {error && <p className="text-red-500 text-sm mt-3">{error}</p>} {/* Larger error text */}
            <button
              onClick={handleGoogleSignUp}
              className="w-full p-4 text-indigo-600 border-2 border-indigo-600 rounded-md hover:bg-indigo-600 hover:text-white flex items-center justify-center transition-colors duration-300"
            >
              {/* Google 'G' logo */}
              <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24" height="24">
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
              <b>Sign up with Google</b>
            </button>

            <div className="text-center mt-6 text-lg"> {/* Larger text */}
              Already have an account?
              <Link to="/login" className="text-indigo-600 hover:underline"> Log in</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUpComponent;