import React, { useState, useEffect } from 'react';
import LineChartContainer from './LineChartContainer';
import { useAuth } from '../Auth/AuthContext';

const Dashboard = ({ userEmail }) => {
    const [graphDisplays, setGraphDisplays] = useState([]);
    const [loadingStates, setLoadingStates] = useState([]);
    const [initialLoading, setInitialLoading] = useState(true); // New state for initial loading
    const { authToken } = useAuth();

    const deleteGraph = async (graphId) => {
        try {
            const response = await fetch('/deleteGraph', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({ email: userEmail, graphId })
            });

            if (response.ok) {
                setGraphDisplays((prevGraphs) =>
                    prevGraphs.filter(graph => graph._id !== graphId)
                );
            } else {
                console.error('Failed to delete the graph.');
            }
        } catch (error) {
            console.error('There was an error deleting the graph.', error);
        }
    };

    const processGraphData = async (graphModel, dbType = 'mongo') => {
        try {
            const response = await fetch('/query', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({
                    email: userEmail,
                    query: graphModel.query,
                    collection: dbType === 'mongo' ? graphModel.collection : '',
                    dbType
                })
            });
    
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
            }
    
            const responseData = await response.json();
            return {
                _id: responseData._id,
                data: responseData,
                xField: graphModel.xField,
                yField: graphModel.yField,
                xLabel: graphModel.xLabel,
                yLabel: graphModel.yLabel,
                title: graphModel.graphTitle
            };
        } catch (error) {
            console.error('Error processing graph data:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (userEmail) {
            setInitialLoading(true); // Start with initial loading
            
            fetch(`/getGraphs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({ email: userEmail })
            })
            .then(response => response.json())
            .then(async (data) => {
                if (data.graphs) {
                    const processedGraphs = await Promise.all(data.graphs.map(async (graph, index) => {
                        try {
                            const graphData = await processGraphData(graph.GraphData, graph.dbType);
                            graphData._id = graph._id;

                            // Set the loading state to false for the loaded graph
                            setLoadingStates(prev => {
                                const newLoadingStates = [...prev];
                                newLoadingStates[index] = false;
                                return newLoadingStates;
                            });

                            return graphData;
                        } catch (error) {
                            console.error('Error processing graph data:', error);
                            return null; // Handle error gracefully or omit the graph
                        }
                    }));

                    const filteredGraphs = processedGraphs.filter(graph => graph !== null);

                    setGraphDisplays(filteredGraphs);
                }
                setInitialLoading(false); // End initial loading
            });
        }
    }, [userEmail, authToken]);

    if (initialLoading) {
        // Centralized loader
        return (
            <div className="flex justify-center items-center h-screen">
                <div role="status">
                                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                </div>
        );
    }

    return (
        <div className="flex justify-center pt-10">
            <div className="w-10/12 md:w-8/12">
                <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                    {graphDisplays.map((graphDisplay, index) => (
                        <div key={index} className="relative">
                            {loadingStates[index] ? (
                                <div className="flex justify-center items-center h-full">
                                <div role="status">
                                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>                            
                            ) : (
                                <>
                                    <LineChartContainer
                                        data={graphDisplay.data}
                                        xField={graphDisplay.xField}
                                        yField={graphDisplay.yField}
                                        xLabel={graphDisplay.xLabel}
                                        yLabel={graphDisplay.yLabel}
                                        graphTitle={graphDisplay.title}
                                    />
<button
  onClick={() => deleteGraph(graphDisplay._id)}
  className="bg-white hover:bg-indigo-100 text-indigo-600 font-bold py-1 px-2 md:py-2 md:px-4 border-2 border-indigo-600 hover:border-indigo-500 hover:text-indigo-700 rounded shadow hover:shadow-md mt-2 md:mt-0 w-full md:w-auto text-xs md:text-sm transition-all mx-auto block"
>
  Delete
</button>


                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
