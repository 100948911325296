import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

import LineChartContainer from './Charts/LineChartContainer';
import LoginComponent from './firebase/LoginComponent';
import SignUpComponent from './firebase/SignupComponent';
import Dashboard from './Charts/Dashboard';
import DatabaseModal from './Modals/DatabaseModal';
import SaveGraphModal from './Modals/SaveGraphModal';
import wishGif from './wish.gif';
import { auth } from './firebase/firebaseConfig';
import { useAuth } from './Auth/AuthContext';
import StripeCheckoutModal from './Modals/StripeCheckoutModal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import cauldronImage from './assets/cauldron.png';
import Alert from './Tailwind/Alert';
import Notification from './Tailwind/Notification';
import Header from './Tailwind/Header';

// const stripePromise = loadStripe('pk_test_WwnmIUnKbYZWWm7w8pRVNBN4')
const stripePromise = loadStripe('pk_live_dWxtHVZKPHKGuysjEd6WRCfL')

function App() {
  const [aggregatePipeline, setAggregatePipeline] = useState('');
  const [collection, setCollection] = useState('');
  const [queryPrompt, setQueryPrompt] = useState('');
  const [data, setData] = useState([]);
  const [graphModel, setGraphModel] = useState({});
  const [dbSchema, setDbSchema] = useState(null);
  const [dbType, setDbType] = useState('mostRecent');
  const [xField, setXField] = useState('_id');
  const [yField, setYField] = useState('count');
  const [xLabel, setXLabel] = useState('X Label');
  const [yLabel, setYLabel] = useState('Y Label');
  const [title, setTitle] = useState('LineChartContainer');
  const [isLoading, setIsLoading] = useState(true);
  const [handleSubmitIsLoading, setHandleSubmitIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [showDatabaseModal, setShowDatabaseModal] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [graphLoaded, setGraphLoaded] = useState(false);
  const { authToken } = useAuth();
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [connectionString, setConnectionString] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [notification, setNotification] = useState({ show: false, type: '', message: { title: '', description: '' } });
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [randomInsightClicked, setRandomInsightClicked] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const resetAllStates = () => {
    setAggregatePipeline('');
    setCollection('');
    setQueryPrompt('');
    setData([]);
    setGraphModel({});
    setDbSchema(null);
    setDbType('mostRecent');
    setXField('_id');
    setYField('count');
    setXLabel('X Label');
    setYLabel('Y Label');
    setTitle('LineChartContainer');
    setIsLoading(false);
    setHandleSubmitIsLoading(false);
    setUser(null);
    setShowDatabaseModal(false);
    setUserEmail('');
    setShowModal(false);
    setGraphLoaded(false);
    setShowStripeModal(false);
    setHasSubscription(false);
    setConnectionString(false);
    setShowToast(false);
    setNotification({ show: false, type: '', message: { title: '', description: '' } });
    setAlertMessage('');
    setShowAlert(false);
  };

  useEffect(() => {
    const checkSubscription = async () => {
      if (userEmail === '') {
        return;
      }
      try {
        const response = await fetch(`/fetch-user-subscription?email=${userEmail}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        });

        const data = await response.json();

        if (data) {
          setHasSubscription(true);
        }
      } catch (error) {
        // console.error("Error fetching subscription:", error);
      }
    }

    checkSubscription();
  }, [userEmail]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        try {
          const idTokenResult = await currentUser.getIdTokenResult();
          if (idTokenResult.claims.exp * 1000 < Date.now()) {
            await auth.signOut();
            resetAllStates();
            if (location.pathname !== '/login') {
              navigate('/login');
            }
          } else {
            setUser(currentUser);
            setUserEmail(currentUser.email);
            if (location.pathname !== '/' && location.pathname !== '/dashboard') {
              navigate('/');
            }
          }
        } catch (error) {
          console.error('Error fetching ID token', error);
        }
      } else {
        resetAllStates();
        if (isLoggingOut) {
          if (location.pathname !== '/login') {
            navigate('/login');
          }
          setIsLoggingOut(false); // Reset the flag after handling the logout redirection
        } else if (location.pathname !== '/signup' && location.pathname !== '/login') {
          navigate('/signup');
        }
      }
    });

    return () => unsubscribe();
  }, [navigate, location.pathname, isLoggingOut]); // Include isLoggingOut in the dependency array


  const triggerNotification = (type, title, description) => {
    setNotification({ show: true, type, message: { title, description } });
    setTimeout(() => {
      setNotification({ ...notification, show: false });
    }, 3000);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleSaveGraph = (updatedData) => { };

  const logout = async () => {
    await auth.signOut();
    resetAllStates();
    setIsLoggingOut(true); // Set the logout state
    navigate('/login');
  };

  async function sendTextToServer(promptText, temperature = 0.2) {
    try {
      const response = await fetch('/sendText', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          prompt: promptText,
          ...(temperature !== 0.2 && { temperature: temperature })
        })
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();
      return data.message;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  const processGraphData = async (graphModel, userEmail) => {
    try {
      const response = await fetch('/query', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          email: userEmail,
          query: graphModel.query,
          collection: dbType === 'mongo' ? graphModel.collection : undefined,
          dbType
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();

      if (responseData.error) {
        throw new Error(responseData.error);
      }

      let transformedData;

      if (dbType === 'airtable') {
        transformedData = responseData.map(item => ({
          [graphModel.xField]: item[0],
          [graphModel.yField]: item[1]
        }));
      } else {
        transformedData = responseData;
      }

      return {
        _id: graphModel._id,
        data: transformedData,
        xField: graphModel.xField,
        yField: graphModel.yField,
        xLabel: graphModel.xLabel,
        yLabel: graphModel.yLabel,
        title: graphModel.graphTitle
      };
    } catch (error) {
      console.error('Error querying:', error);
      return { error: error.message };
    }
  };

  const handleSubmit = async (overridePrompt) => {
    try {
      setShowAlert(false);
      setGraphLoaded(false);
      const schemaString = JSON.stringify(dbSchema, null, 2);
      var prompt;

      const effectivePrompt = overridePrompt || queryPrompt;
      if (dbType === 'airtable') { // airtable
        prompt = `Given an Airtable schema ${schemaString}, create a JavaScript function body as a string. This string should contain the code to process an array of Airtable records. Do not include the 'function' keyword or parameter list in this string; it should be only the inner code of the function.
        Ensure this function returns an 2d array so it can be graphed. The first element of each array should be the x-axis value, and the second element should be the y-axis value.
      Here is the prompt form the user, which explains what data he wants to see from his database (on a graph). This means you need to analyze his schema, set the baseId, tableId, and options,
      then set fnBody to a javascript function that can be ran by const fn = new Function('records', query.fnBody); const result = fn(allRecords) ?? []; Make sure after the function is ran, the results are in an array of objects where key is also the name stored for xField, and value is actual value of query, then same for y... yField, and the y value after query function. That's your task. Here's the user's prompt:
      ${effectivePrompt}

      And here's what the format that will be passed into the fn:
      (The Airtable API response) A collection of record objects. Each record object typically includes:

      - An 'id' key with a string value representing the unique identifier of the record.
      - A 'createdTime' key with a string value in ISO 8601 format indicating when the record was created.
      - A 'fields' key which is an object holding key-value pairs. The keys are the names of the fields (columns) in the Airtable table, and the values are the corresponding data entries for those fields in the given record.

      The 'fields' object structure mirrors the table design in Airtable, with each key-value pair representing a field and its value for that particular record.

      
      Now here is the expected format for the complete response from chatgpt:
      
      {
        "xField": "(field name of whatever you called the x axis field in the post function result)",
        "yField": "(field name of whatever you called the y axis field in the post function result)",
        "xLabel": "XAxisLabel", "yLabel": "YAxisLabel", "graphTitle": "GraphTitle"
        "query": {
          "baseId": "BASE_ID_NEEDED_FROM_SCHEMA",
          "tableId": "TABLE_ID_NEEDED_FROM_SCHEMA",
          "options": {
            "fields": [] // optional
          },
          "fnBody": "THE_STRINGIFIED_FUNCTION"
        }
      }
      Notes:
      - The result returned from chatgpt should be stringified json. I must be able to parse it, or my program will crash.
      - Never make up field names. Always use the field names from the schema. If the user wants to change the field name, he can do that in the schema.
     `
      } else if (!connectionString && dbType === 'mongo') {
        const pipelineString = `[
        { "$group": { "_id": { "$dateToString": { "format": "%m/%Y", "date": "$InsertDate" } }, "count": { "$sum": 1 } } },
        { "$sort": { "_id": 1 } }
      ]`;

        const formattedPipelineString = `[
        {
          "$group": {
            "_id": {
              "$dateToString": {
                "format": "%m/%Y",
                "date": "$InsertDate"
              }
            },
            "count": {
              "$sum": 1
            }
          }
        },
        {
          "$sort": {
            "_id": 1
          }
        }
      ]`; // Multi-line string with newlines

        prompt = `Given the schema: "${schemaString}", provide the MongoDB aggregate pipeline query for the instruction: ${effectivePrompt}. Format the response as: { "collection": "CollectionName", "query": "${formattedPipelineString}", "xField": "XAxisFieldName", "yField": "YAxisFieldName", "xLabel": "XAxisLabel", "yLabel": "YAxisLabel", "graphTitle": "GraphTitle" }. The stringified query should not use actual JSON array or object notation, but be a pure string representation. Please ensure the query has new lines and tabs so it is nicely formatted for when I print it on my website's HTML. Fyi, today's date is ${new Date()}. Display dates on a graph in the format month/year (e.g., 9/11). The returned structure should support multiple indexes for graphing.`;
        // prompt = `Given the schema: "${schemaString}", provide the MongoDB aggregate pipeline query for the instruction: 
        // ${effectivePrompt}. Format the response as: { "collection": "CollectionName", "query": "${formattedPipelineString}" }. 
        // The stringified query should not use actual JSON array or object notation, but be a pure string representation, so when I run JSON.parse() on this entire object, the value of query is still a string. 
        // Please ensure the query has new lines and tabs so it is nicely formatted for when I print it on my website's HTML. 
        // Fyi, today's date is ${new Date()}.`; // Talk to your db
      } else if (dbType === 'mongo') {
        prompt = `Given the schema: "${schemaString}", provide the MongoDB aggregate pipeline query including collection name (choose the correct collection name) to run the pipeline on for the instruction: ${effectivePrompt}. Additionally, specify the fields to be used for the x and y axes in the graph, and suggest a title for the graph. Format the response as: { "collection": "CollectionName", "query": [ ... ], "xField": "XAxisFieldName", "yField": "YAxisFieldName", "xLabel": "XAxisLabel", "yLabel": "YAxisLabel", "graphTitle": "GraphTitle" }. It needs to be JSON-serializable. Generate a MongoDB query in a JSON-compatible format, avoiding the use of MongoDB-specific data types like ISODate or ObjectId. Fyi, today's date is` + new Date() + `. Return any dates as an ISO string in the format /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z)$/. When you display dates on a graph, show them in the format month/year (e.g. 9/11). Always return a data structure with multiple indexes so we can put it on a graph.`;
      } else {
        console.log('whoops, dbType: ', dbType)
      }

      setHandleSubmitIsLoading(true);
      const temperature = randomInsightClicked ? 0.7 : 0.2;
      const res = await sendTextToServer(prompt, temperature);
      const parsedResponse = JSON.parse(res);

      if (connectionString) {
        const graphData = await processGraphData(parsedResponse, userEmail);
        setData(graphData.data);
        if (graphData.data.length == 0) {
          setAlertMessage("0 results returned.");
          setShowAlert(true);
        }
        setXField(graphData.xField);
        setXLabel(graphData.xLabel);
        setYLabel(graphData.yLabel);
        setYField(graphData.yField);
        setTitle(graphData.title);
        setGraphModel(parsedResponse);
        setGraphLoaded(true);
      }
      if (dbType == 'mongo') {
        setAggregatePipeline(parsedResponse.query);
        setCollection(parsedResponse.collection);
      } else if (dbType == 'airtable') {
        setAggregatePipeline(parsedResponse.query.fnBody)
      }
      setHandleSubmitIsLoading(false);
      setRandomInsightClicked(false);
    } catch (err) {
      setHandleSubmitIsLoading(false);
      setRandomInsightClicked(false);
      setAlertMessage("We're sorry, something happened on our end. Please try again.\nIf this keeps happening again, please email us at adam@magicdashai.com and explain what happened.");
      setShowAlert(true);
      console.error(err);
    }
  };

  useEffect(() => {
    try {
      if (userEmail && authToken && dbType) {
        getUserConnectionString();
      }
    } catch (error) {
      console.error("Error getting user connection string:", error);
    }
  }, [userEmail]);

  useEffect(() => {
    if (randomInsightClicked) {

      // Now proceed with your action
      if (!hasSubscription) {
        setShowStripeModal(true);
      } else {
        handleSubmit("I am a business owner and you have access to my db schema. Show me a helpful insight that you'd guess will help me analyze a KPI. Ensure that array to be plotted on the graph only has 3-7 records.");
      }
    }
  }, [randomInsightClicked, queryPrompt]); // Dependencies array

  const handleOpenDatabaseModal = () => {
    setShowDatabaseModal(true);
  };

  const handleCloseDatabaseModal = () => {
    setShowDatabaseModal(false);
  };

  const getUserConnectionString = (customDbType = dbType) => {
    return fetch(`/getUserSchema?email=${encodeURIComponent(userEmail)}&dbType=${encodeURIComponent(customDbType)}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    })
      .then(async response => {
        if (response.status === 401) {
          await auth.signOut();
          resetAllStates();
          if (location.pathname !== '/login') {
            navigate('/login');
          }
          throw new Error('Unauthorized access');
        }
        return response.json();
      })
      .then(res => {
        try {
          if (res === {} || !res.success || res.schema === null) {
            setDbSchema(null);
            throw new Error('Failed to retrieve schema with success status.');
          }
          setDbSchema(res.schema);
          setDbType(res.dbType);
          if (res.hasConnectionString !== null) {
            setConnectionString(res.hasConnectionString);
          }
        } catch (error) {
          return error;
        } finally {
          setIsLoading(false);
        }
      });
  };



  const handleSaveDatabaseSettings = async (connectionString, dbType) => {
    try {
      setIsLoading(true);
      const response = await fetch('/updateConnectionString', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          email: userEmail,
          connectionString,
          dbType
        })
      });

      if (!response.ok) {
        throw new Error(`Failed to update ${dbType} settings`);
      }

      await new Promise(resolve => setTimeout(resolve, 2000));
      triggerNotification('success', 'Database Settings Updated', 'Your database settings have been successfully updated.');
      getUserConnectionString(dbType);
    } catch (error) {
      triggerNotification('error', 'Update Failed', error.message);
    } finally {
      setIsLoading(false);
    }
  };


  const handleSchemaSubmit = async (schemaResult) => {
    try {
      const response = await fetch('/setUserSchema', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          email: userEmail,
          schema: schemaResult
        })
      });

      const data = await response.json();
      return data
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const handleDbTypeChange = (event) => {
    try {
      setDbType(event.target.value);
      getUserConnectionString(event.target.value);
    } catch (error) {
      console.error("Error changing dbType:", error);
    }
  };

  if (!user) {
    return (
      <div style={{ backgroundColor: '#F2F2F2' }} className="min-h-screen p-12">
        <Routes>
          <Route path="/login" element={<LoginComponent />} />
          <Route path="/signup" element={<SignUpComponent />} />
        </Routes>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: '#F8F7FC' }} className="min-h-screen p-12">
      {notification.show && (
        <div className="fixed top-4 right-4 z-50">
          <Notification
            alertType={notification.type}
            message={notification.message}
            onClose={() => setNotification({ ...notification, show: false })}
          />
        </div>
      )}
      <div className="w-full md:grid md:grid-cols-12">
        <div className="md:col-start-2 md:col-span-10">
          <div className="w-full md:w-12/12">
            <Header
              dbSchema={dbSchema}
              hasSubscription={hasSubscription}
              handleOpenDatabaseModal={handleOpenDatabaseModal}
              setShowStripeModal={setShowStripeModal}
              logout={logout}
              userEmail={userEmail}
              stripePromise={stripePromise}
              showStripeModal={showStripeModal}
            />
          </div>
          {/* This div below the Header will have the same styling as the Header */}
          <div className="w-full md:w-12/12 bg-white shadow-sm rounded-xl p-4 mt-5">


            {showDatabaseModal && <DatabaseModal handleSchemaSubmit={handleSchemaSubmit} onClose={handleCloseDatabaseModal} onSave={handleSaveDatabaseSettings} />}
            <Routes>
              <Route path="/dashboard" element={<Dashboard userEmail={userEmail} />} />
              <Route path="/" element={
                <>
                  {dbSchema ? (
                    <div className="flex flex-col items-center mb-8 space-y-4 p-3">
                      <div className="flex flex-col md:flex-row md:items-center">
                        <div className="grid grid-cols-12 gap-4">
                          <select
                            value={dbType}
                            onChange={handleDbTypeChange}
                            className="col-span-5 md:col-span-2 py-3 px-4 text-sm font-semibold rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 transition-all duration-150 ease-in-out"
                          >
                            <option value="mongo">MongoDB</option>
                            <option value="airtable">Airtable</option>
                          </select>

                          <textarea
                            id="queryPrompt"
                            name="queryPrompt"
                            rows="1"
                            className="col-span-7 md:col-span-10 p-3 bg-white rounded-lg border border-gray-300 placeholder-gray-500 text-sm text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 transition-all duration-150 ease-in-out"
                            value={queryPrompt}
                            onChange={(e) => setQueryPrompt(e.target.value)}
                            placeholder="Show me the number of users that signed up each month for the last 12 months"
                          ></textarea>
                        </div>


                      </div>

                      <div className="flex flex-col sm:flex-row items-center space-y-2 md:space-y-0 sm:space-x-4">
                        <button
                          onClick={() => {
                            if (!hasSubscription) {
                              setShowStripeModal(true);
                            } else {
                              handleSubmit();
                            }
                          }}
                          className={`bg-indigo-600 hover:bg-indigo-400 text-white font-bold py-1 px-2 md:py-2 md:px-4 border-b-2 border-indigo-700 hover:border-indigo-500 rounded mt-2 md:mt-0 w-full md:w-auto text-xs md:text-sm ${handleSubmitIsLoading && !randomInsightClicked ? "animate-bounce" : ""
                            }`}
                        >
                          Visualize ✨
                        </button>
                        <button
  onClick={() => {
    setQueryPrompt("...generating an insight for you");
    setRandomInsightClicked(true);
  }}
  className={`bg-white hover:bg-indigo-100 text-indigo-600 font-bold py-1 px-2 md:py-2 md:px-4 border-2 border-indigo-600 hover:border-indigo-500 hover:text-indigo-700 rounded shadow hover:shadow-none mt-2 md:mt-0 w-full md:w-auto text-xs md:text-sm transition-all ${
    randomInsightClicked && handleSubmitIsLoading ? "animate-bounce" : ""
  }`}
>
  Surprise me
</button>

                      </div>



                      {showStripeModal && (
                        <Elements stripe={stripePromise}>
                          <StripeCheckoutModal onClose={() => setShowStripeModal(false)} email={userEmail ? userEmail : 'guest@example.com'} />
                        </Elements>
                      )}
                    </div>
                  ) : !isLoading && (
                    <div className="flex flex-col items-center mb-8">
                      <div class="w-full bg-indigo-100 border-l-4 border-indigo-500 text-indigo-700 p-4 cursor-pointer" onClick={handleOpenDatabaseModal} role="alert">
                        <strong className="block mb-2">Start by connecting your database!</strong>
                        <span className="underline">Need help? Check the settings</span>.
                      </div>
                    </div>

                  )}

                  <div className="flex justify-center items-start space-x-4">
                    {/* {aggregatePipeline && (
                    <div className="w-2/5 p-4 border rounded-lg shadow-lg bg-white">
                      {dbType === 'mongo' && (
                        <h2 className="text-xl mb-4">
                          Collection to run pipeline on: <span className="font-semibold">{collection}</span>
                        </h2>
                      )}
                      <div className="rounded-md overflow-auto border border-gray-300 p-2">
                        {connectionString ? (
                          <pre className="whitespace-pre-wrap text-sm">
                            {JSON.stringify(aggregatePipeline, null, 2)}
                          </pre>
                        ) : (
                          <pre className="whitespace-pre-wrap text-sm">{aggregatePipeline}</pre>
                        )}
                      </div>
                    </div>
                  )} */}

                    <div className="w-5/5">
                      {isLoading ? (
                                    <div className="flex justify-center items-center h-screen">

          <div role="status">
              <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
          </div>

</div>                      
                      ) : (
                        <LineChartContainer
                          data={data}
                          xField={xField}
                          yField={yField}
                          xLabel={xLabel}
                          yLabel={yLabel}
                          graphTitle={title}
                        />
                      )}

                      <div className='flex justify-center items-center'>
                        {(graphLoaded && !showAlert) && (
                          <button
                            onClick={handleOpenModal}
                            className="bg-white hover:bg-indigo-100 text-indigo-600 font-bold py-1 px-2 md:py-2 md:px-4 border-2 border-indigo-600 hover:border-indigo-500 hover:text-indigo-700 rounded shadow hover:shadow-none mt-2 md:mt-0 w-full md:w-auto text-xs md:text-sm transition-all"
                          >
                            Save Visualization to Dashboard
                          </button>
                        )}
                        {showAlert && (
                          <Alert title={alertMessage} />
                        )}
                        {showModal && (
                          <SaveGraphModal
                            onClose={() => setShowModal(false)}
                            data={graphModel}
                            onSave={handleSaveGraph}
                            userEmail={userEmail ? userEmail : 'guest@example.com'}
                            dbType={dbType}
                          />
                        )}
                      </div>
                    </div>
                  </div>


                </>
              } />
            </Routes>
          </div>
        </div></div></div>

  );
}

export default App;

